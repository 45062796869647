
import { Component, Vue } from 'vue-property-decorator';
import {LMap, LTileLayer} from 'vue2-leaflet';

@Component({
    components: {
        LMap,
    LTileLayer,
    },
})

export default class About extends Vue {
  public google_map = {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 3,
    center: [47.413220, -1.219482],
    bounds: null
  } 
  public zoomUpdated (zoom) {
    this.google_map.zoom = zoom;
    };
  public centerUpdated (center) {
    this.google_map.center = center;
  };
  public  boundsUpdated (bounds) {
    this.google_map.bounds = bounds;
  }
}
